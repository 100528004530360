import {
  ORG_DATA_STARTED,
  ORG_DATA_SUCCESS,
  ORG_DATA_FAIL,
  ORG_DATA_UPDATE_STARTED,
  ORG_DATA_UPDATE_SUCCESS,
  ORG_DATA_UPDATE_FAIL,
} from '../actions/actionTypes';

const initialState = {
  school: {},
  orgLoading: false,
  orgError: '',
};

const organization = (state = initialState, action: any) => {
  switch (action.type) {
    case ORG_DATA_SUCCESS:
    case ORG_DATA_UPDATE_SUCCESS:
      const { school } = action.payload;
      return {
        school,
        orgLoading: false,
        orgError: '',
      };
    case ORG_DATA_STARTED:
    case ORG_DATA_UPDATE_STARTED:
      return {
        ...state,
        orgLoading: true,
        orgError: '',
      };
    case ORG_DATA_FAIL:
    case ORG_DATA_UPDATE_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        orgError: error,
        orgLoading: false,
      };
    }
    default:
      return state;
  }
};
export default organization;
