import {
  ADD_CAMERA_STARTED,
  ADD_CAMERA_SUCCESS,
  ADD_CAMERA_FAIL,
  CAMERA_DATA_STARTED,
  CAMERA_DATA_SUCCESS,
  CAMERA_DATA_FAIL,
  REMOVE_CAMERA_STARTED,
  REMOVE_CAMERA_SUCCESS,
  REMOVE_CAMERA_FAIL,
  CAMERA_DATA_UPDATE_STARTED,
  CAMERA_DATA_UPDATE_FAIL,
  CAMERA_DATA_UPDATE_SUCCESS,
} from 'redux/actions/actionTypes';

const initialState = {
  cameras: [], // Camera details
  cameraLoading: false, // Showing that the API is in progress
  cameraError: '', // Manage Error in camera API
  removeCameraLoading: false,
  removeCameraError: '',
};

const camera = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_CAMERA_STARTED:
    case CAMERA_DATA_STARTED:
    case CAMERA_DATA_UPDATE_STARTED: {
      return {
        ...state,
        cameraLoading: true,
        cameraError: '',
        removeCameraLoading: false,
        removeCameraError: '',
      };
    }

    case ADD_CAMERA_SUCCESS: {
      return {
        ...state,
        cameraLoading: false,
        cameraError: '',
        removeCameraLoading: false,
        removeCameraError: '',
      };
    }

    case CAMERA_DATA_SUCCESS: {
      const { cameras } = action.payload;
      return {
        cameras,
        cameraLoading: false,
        cameraError: '',
        removeCameraLoading: false,
        removeCameraError: '',
      };
    }

    case CAMERA_DATA_UPDATE_SUCCESS: {
      return {
        ...state,
        cameraLoading: false,
        cameraError: '',
        removeCameraLoading: false,
        removeCameraError: '',
      };
    }

    case ADD_CAMERA_FAIL:
    case CAMERA_DATA_FAIL:
    case CAMERA_DATA_UPDATE_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        cameraLoading: false,
        cameraError: error,
        removeCameraLoading: false,
        removeCameraError: '',
      };
    }

    case REMOVE_CAMERA_STARTED: {
      return {
        ...state,
        cameraLoading: false,
        cameraError: '',
        removeCameraLoading: true,
        removeCameraError: '',
      };
    }

    case REMOVE_CAMERA_SUCCESS: {
      return {
        ...state,
        cameraLoading: false,
        cameraError: '',
        removeCameraLoading: false,
        removeCameraError: '',
      };
    }

    case REMOVE_CAMERA_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        cameraLoading: false,
        cameraError: '',
        removeCameraLoading: false,
        removeCameraError: error,
      };
    }

    default:
      return state;
  }
};

export default camera;
