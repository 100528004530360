import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { verifyTokenAsync } from './redux/asyncActions/authAsyncActions';
import { useSelector, useDispatch } from 'react-redux';
import AuthBase from './AuthBase';
import ScrollToTop from 'components/ScrollToTop';
import { Router } from './router/Router';
import { Toaster } from 'react-hot-toast';

/**
 * Top level application router
 *
 * @returns {Component}
 */
export default function App(): React.ReactElement {
  const dispatch = useDispatch();
  const authObj = useSelector((state: any) => state.auth);
  const { isAuthenticated } = authObj;

  useEffect(() => {
    dispatch(verifyTokenAsync());
  }, [dispatch]);

  return (
    <HelmetProvider>
      <div>
        {isAuthenticated === true ? (
          <AuthBase />
        ) : (
          <ScrollToTop>
            <Router />
          </ScrollToTop>
        )}
        <Toaster containerClassName='font-sans font-medium' />
      </div>
    </HelmetProvider>
  );
}
