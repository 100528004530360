import { AxiosError } from 'axios';
import {
  verifyTokenStarted,
  verifyUserSuccess,
  verifyTokenEnd,
  userLoginStarted,
  userLoginFailure,
  userLogout,
} from '../actions/authActions';
import http from 'utils/http';
import config from 'config';

// handle verify token
export const verifyTokenAsync =
  (silentAuth = false) =>
  async (dispatch: any) => {
    dispatch(verifyTokenStarted(silentAuth));
    const url = config.endpoints.auth.refreshToken;
    try {
      const { data, status } = await http.post(url);
      if (status === 204) {
        dispatch(verifyTokenEnd());
        return;
      } else if (status === 200) {
        dispatch(verifyUserSuccess(data));
        return;
      } else {
        dispatch(userLogout());
        return;
      }
    } catch (error) {
      const err = error as AxiosError;
      dispatch(verifyTokenEnd());
      if (err && err.response && [401, 403].includes(err.response.status))
        dispatch(userLogout());
      return;
    }
  };

// handle user login
export const userLoginAsync = (loginData: any) => async (dispatch: any) => {
  dispatch(userLoginStarted());
  const url = config.endpoints.auth.login;
  try {
    const { data }: any = await http.post(url, loginData);
    dispatch(verifyUserSuccess(data));
  } catch (error) {
    const err = error as AxiosError;
    const { response: { data = {} } = {} } = err;
    dispatch(userLoginFailure(data && data.message));
    return;
  }
};

// handle user logout
export const userLogoutAsync = () => async (dispatch: any) => {
  const url = config.endpoints.auth.logout;
  try {
    const { status }: any = await http.post(url);
    if (status === 200) {
      dispatch(userLogout());
    }
  } catch (err) {
    return;
  }
};
