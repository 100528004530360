import { combineReducers } from 'redux';
import auth from './authReducers';
import org from './organizationReducers';
import camera from './cameraReducers';
import user from './userReducers';
import subscriber from './subscriberReducer';

// to combine all reducers together
const appReducer = combineReducers({ auth, org, camera, user, subscriber });

export default appReducer;
