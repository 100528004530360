import {
  ADD_USER_STARTED,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  REMOVE_USER_STARTED,
  REMOVE_USER_FAIL,
  REMOVE_USER_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  userLoading: false,
  removeuserLoading: false,
  addUserError: '',
  removeUserError: '',
};

const user = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_USER_STARTED: {
      return {
        ...state,
        addUserError: '',
        userLoading: true,
      };
    }

    case REMOVE_USER_STARTED: {
      return {
        ...state,
        removeuserLoading: true,
        addUserError: '',
      };
    }

    case ADD_USER_SUCCESS: {
      return {
        ...state,
        userLoading: false,
        addUserError: '',
      };
    }

    case REMOVE_USER_SUCCESS: {
      return {
        ...state,
        removeuserLoading: false,
        addUserError: '',
      };
    }

    case ADD_USER_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        addUserError: error,
        userLoading: false,
      };
    }

    case REMOVE_USER_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        removeUserError: error,
        removeuserLoading: false,
        addUserError: '',
      };
    }

    default:
      return state;
  }
};

export default user;
