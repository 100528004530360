export const VERIFY_TOKEN_STARTED = 'VERIFY_TOKEN_STARTED';
export const VERIFY_TOKEN_END = 'VERIFY_TOKEN_END';

export const USER_LOGIN_STARTED = 'USER_LOGIN_STARTED';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_LAST_VISITED_ROUTE = 'UPDATE_LAST_VISITED_ROUTE';

export const ORG_DATA_STARTED = 'ORG_DATA_STARTED';
export const ORG_DATA_SUCCESS = 'ORG_DATA_SUCCESS';
export const ORG_DATA_FAIL = 'ORG_DATA_FAIL';

export const ORG_DATA_UPDATE_STARTED = 'ORG_DATA_UPDATE_STARTED';
export const ORG_DATA_UPDATE_SUCCESS = 'ORG_DATA_UPDATE_SUCCESS';
export const ORG_DATA_UPDATE_FAIL = 'ORG_DATA_UPDATE_FAIL';

export const ADD_CAMERA_STARTED = 'ADD_CAMERA_STARTED';
export const ADD_CAMERA_SUCCESS = 'ADD_CAMERA_SUCCESS';
export const ADD_CAMERA_FAIL = 'ADD_CAMERA_FAIL';

export const CAMERA_DATA_STARTED = 'CAMERA_DATA_STARTED';
export const CAMERA_DATA_SUCCESS = 'CAMERA_DATA_SUCCESS';
export const CAMERA_DATA_FAIL = 'CAMERA_DATA_FAIL';

export const CAMERA_DATA_UPDATE_STARTED = 'CAMERA_DATA_UPDATE_STARTED';
export const CAMERA_DATA_UPDATE_SUCCESS = 'CAMERA_DATA_UPDATE_SUCCESS';
export const CAMERA_DATA_UPDATE_FAIL = 'CAMERA_DATA_UPDATE_FAIL';

export const REMOVE_CAMERA_STARTED = 'REMOVE_CAMERA_STARTED';
export const REMOVE_CAMERA_SUCCESS = 'REMOVE_CAMERA_SUCCESS';
export const REMOVE_CAMERA_FAIL = 'REMOVE_CAMERA_FAIL';

export const ADD_USER_STARTED = 'ADD_USER_STARTED';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';

export const REMOVE_USER_STARTED = 'REMOVE_USER_STARTED';
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS';
export const REMOVE_USER_FAIL = 'REMOVE_USER_FAIL';

export const SUBSCRIBER_DATA_STARTED = 'SUBSCRIBER_DATA_STARTED';
export const SUBSCRIBER_DATA_SUCCESS = 'SUBSCRIBER_DATA_SUCCESS';
export const SUBSCRIBER_DATA_FAIL = 'SUBSCRIBER_DATA_FAIL';

export const ADD_SUBSCRIBER_STARTED = 'ADD_SUBSCRIBER_STARTED';
export const ADD_SUBSCRIBER_SUCCESS = 'ADD_SUBSCRIBER_SUCCESS';
export const ADD_SUBSCRIBER_FAIL = 'ADD_SUBSCRIBER_FAIL';

export const SUBSCRIBER_DATA_UPDATE_STARTED = 'SUBSCRIBER_DATA_UPDATE_STARTED';
export const SUBSCRIBER_DATA_UPDATE_SUCCESS = 'SUBSCRIBER_DATA_UPDATE_SUCCESS';
export const SUBSCRIBER_DATA_UPDATE_FAIL = 'SUBSCRIBER_DATA_UPDATE_FAIL';

export const REMOVE_SUBSCRIBER_STARTED = 'REMOVE_SUBSCRIBER_STARTED';
export const REMOVE_SUBSCRIBER_SUCCESS = 'REMOVE_SUBSCRIBER_SUCCESS';
export const REMOVE_SUBSCRIBER_FAIL = 'REMOVE_SUBSCRIBER_FAIL';
