/**
 * Application wide configuration.
 */
import { BASEURL } from "./baseUrl";
const config = {
  env: process.env.NODE_ENV,
  endpoints: {
    auth: {
      login: `${BASEURL}/auth/login`,
      register: `${BASEURL}/auth/register`,
      logout: `${BASEURL}/auth/logout`,
      refreshToken: `${BASEURL}/auth/refresh-token`,
      forgotPassword: `${BASEURL}/auth/forgot-password`,
      changePassword: `${BASEURL}/auth/change-password`,
      verifyPassword: `${BASEURL}/auth/verify-password`,
      storeError: `${BASEURL}/auth/error`,
    },
    user: {
      allUsers: `${BASEURL}/user`,
      removeUser: `${BASEURL}/user/remove`,
      addUser: `${BASEURL}/user/create`,
    },
    organization: {
      getOrganization: `${BASEURL}/organization/get`,
      updateOrganization: `${BASEURL}/organization/update`,
    },
    camera: {
      getCamera: `${BASEURL}/camera/get`,
      addCamera: `${BASEURL}/camera/create`,
      updateCamera: `${BASEURL}/camera/update`,
      removeCamera: `${BASEURL}/camera/remove`,
      getCameraStreamsVideos: `${BASEURL}/camera/streams`,
      metadata: `${BASEURL}/camera/metadata`,
      getEmotions: `${BASEURL}/camera/emotions`,
    },
    subscriber: {
      getSubscriber: `${BASEURL}/subscriber/get`,
      addSubscriber: `${BASEURL}/subscriber/add`,
      updateSubscriber: `${BASEURL}/subscriber/update`,
      removeSubscriber: `${BASEURL}/subscriber/remove`,
    },
    redact: {
      getIframeURL: `${BASEURL}/redact`,
      getBucketVideo: `${BASEURL}/redact/bucket/videos`,
      uploadVideo: `${BASEURL}/redact/bucket/upload`,
      downloadVideo: `${BASEURL}/redact/bucket/download`,
      getAllCustomVideo: `${BASEURL}/redact/custom-video/get`,
      removeCustomVideo: `${BASEURL}/redact/custom-video/remove`,
    },
    notifications: {
      getAll: `${BASEURL}/notify/get`,
    },
    upload: {
      createMultipart: `${BASEURL}/upload/create-multipart`,
      completeMultipart: `${BASEURL}/upload/complete-multipart`,
      getPresignedUrl: `${BASEURL}/upload/get-presigned-url`,
    },
    log: {
      get: `${BASEURL}/log`,
    },
  },
  role: {
    ADMIN: `ADMIN`,
  },
};

export default config;
