import {
  ArchiveBoxIcon,
  BuildingOfficeIcon,
  CameraIcon,
  CodeBracketIcon,
  DocumentPlusIcon,
  HomeIcon,
  InboxIcon,
  UserGroupIcon,
  UserPlusIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
const BASE: string = "/eai";
export const Navigation: any = [
  // {
  //   name: "Dashboard",
  //   href: `${BASE}/dashboard`,
  //   icon: <HomeIcon className="h-6 w-6" />,
  // },
  // {
  //   name: "Camera",
  //   href: `${BASE}/camera`,
  //   icon: <CameraIcon className="h-6 w-6" />,
  // },
  // {
  //   name: "Organization",
  //   href: `${BASE}/organization`,
  //   icon: <BuildingOfficeIcon className="h-6 w-6" />,
  // },
  // {
  //   name: "Users",
  //   href: `${BASE}/users`,
  //   icon: <UserGroupIcon className="h-6 w-6" />,
  // },
  // {
  //   name: "Subscribers",
  //   href: `${BASE}/subscribers`,
  //   icon: <UserPlusIcon className="h-6 w-6" />,
  // },
  {
    name: "Redact",
    href: `${BASE}/redact`,
    icon: <VideoCameraIcon className="h-6 w-6" />,
  },
  // {
  //   name: "Juno Archive",
  //   href: `${BASE}/archive`,
  //   icon: <ArchiveBoxIcon className="h-6 w-6" />,
  // },
  // {
  //   name: "Custom Video",
  //   href: `${BASE}/custom-video`,
  //   icon: <DocumentPlusIcon className="h-6 w-6" />,
  // },
  // {
  //   name: "Notifications",
  //   href: `${BASE}/notifications`,
  //   icon: <InboxIcon className="h-6 w-6" />,
  // },
  // {
  //   name: "Logs",
  //   href: `${BASE}/logs`,
  //   icon: <CodeBracketIcon className="h-6 w-6" />,
  // },
];
