import { lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import LazyLoader from 'components/LazyLoader';
import * as routes from 'constants/Routes';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
const Home = lazy(() => import('pages/Home'));
const Login = lazy(() => import('pages/Login'));
const Register = lazy(() => import('pages/Register'));
const ForgotPass = lazy(() => import('pages/ForgotPass'));
const ChangePass = lazy(() => import('pages/ChangePass'));
const AuthDashboard = lazy(() => import('pages/Auth'));
const NotFound = lazy(() => import('pages/NotFound'));

/**
 * Top level application router
 *
 * @returns {Component}
 */
const ROUTES = ['eai'];

export const Router = (): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const authObj = useSelector((state: any) => state.auth);
  const { isAuthenticated, role } = authObj;

  useEffect(() => {
    if (role !== null) {
      let url = `eai/dashboard`;
      const getLastLocation = localStorage.getItem('location');
      if (getLastLocation && getLastLocation !== undefined) {
        url = getLastLocation;
      }
      navigate(url);
    }
    // eslint-disable-next-line
  }, [role]);

  useEffect(() => {
    const privateRoutes = location.pathname.split('/');
    if (
      location.pathname !== null &&
      ROUTES.includes(privateRoutes && privateRoutes[1])
    ) {
      localStorage.setItem('location', location.pathname);
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <Routes>
      <Route
        path={routes.HOME}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated}>
              <Home />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.LOGIN}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated}>
              <Login />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.REGISTER}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated}>
              <Register />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.FORGOTPASSWORD}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated}>
              <ForgotPass />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.CHANGEPASSWORD}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated}>
              <ChangePass />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={`${routes.EAI}/*`}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <AuthDashboard />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path='*'
        element={
          <Suspense fallback={<LazyLoader />}>
            <NotFound />
          </Suspense>
        }
      />
    </Routes>
  );
};
