import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState, Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Logo from "assets/images/logoAI.png";
import { Navigation } from "constants/Navigation";
import { userLogoutAsync } from "redux/asyncActions/authAsyncActions";
import Spinner from "./Spinner";

const MobileNavBar = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);

  const logout = async () => {
    try {
      setLoader(true);
      setTimeout(() => {
        dispatch(userLogoutAsync());
        setLoader(false);
        localStorage.removeItem("location");
      }, 1000);
    } catch (err) {
      setLoader(false);
    }
  };

  return (
    <Popover className="md:hidden fixed left-0 right-0 top-0 z-10 bg-white print:hidden">
      <div className="px-4 sm:px-6">
        <div className="flex justify-between items-center py-2">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <NavLink
              className="flex items-center no-underline-all"
              aria-label="Dashboard"
              to="/eai"
            >
              <img
                className="mx-auto h-16 w-auto"
                src={Logo}
                alt="JunoAI"
                loading="lazy"
              />
            </NavLink>
          </div>

          <div className="md:hidden flex">
            <div className="flex space-x-4 items-center">
              <Popover.Button className="p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-800 hover:bg-brand-700 focus:outline-none hover:rounded-full">
                <span className="sr-only">Open menu</span>
                <Bars3Icon
                  className="h-10 w-10 text-brand"
                  aria-hidden="true"
                />
              </Popover.Button>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="z-10 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img
                      className="h-14 w-auto"
                      src={Logo}
                      alt="JunoAI"
                      loading="lazy"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-2">
                    {Navigation &&
                      Navigation.map((item: any) => (
                        <Popover.Button
                          key={item.href}
                          as={NavLink}
                          className={`${
                            location.pathname === `${item.href}`
                              ? "text-brand"
                              : "text-black"
                          } group flex items-center px-2 py-2 text-base no-underline-all font-semibold hover:text-brand`}
                          aria-current="page"
                          to={`${item.href}`}
                        >
                          {item.icon}
                          <span className="ml-3">{item.name}</span>
                        </Popover.Button>
                      ))}
                  </nav>
                  <div className="mt-10">
                    <button
                      className="group relative flex items-center justify-center w-full p-3 bg-brand text-white font-medium rounded-full"
                      onClick={logout}
                    >
                      {loader ? (
                        <div>
                          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                            <Spinner />
                          </span>
                          <span className="tracking-wider">Logging out...</span>
                        </div>
                      ) : (
                        <div>
                          <span className="tracking-wider">Logout</span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
};

export default React.memo(MobileNavBar);
