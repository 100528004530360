import { useSelector } from "react-redux";
import Logo from "./Logo";
const LazyLoader = () => {
  const authObj = useSelector((state: any) => state.auth);
  const { role } = authObj;

  return (
    <div
      className={`${
        role === null ? "h-screen" : "callback-height"
      } flex justify-center items-center space-y-1 bg-squeeze`}
    >
      <div className="m-auto max-w-md flex flex-col justify-center items-center">
        <Logo />
        <div className="flex flex-column items-center space-x-2 relative">
          <span className="flex h-3 w-3 relative">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-brand-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-brand"></span>
          </span>
          <span className="flex h-3 w-3 relative">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-brand-700 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-brand-600"></span>
          </span>
          <span className="flex h-3 w-3 relative">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-brand-900 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-brand-700"></span>
          </span>
        </div>
        <h5 className="font-semibold text-brand">Please Wait....</h5>
      </div>
    </div>
  );
};
export default LazyLoader;
