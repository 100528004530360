import {
  SUBSCRIBER_DATA_STARTED,
  SUBSCRIBER_DATA_SUCCESS,
  SUBSCRIBER_DATA_FAIL,
  ADD_SUBSCRIBER_STARTED,
  ADD_SUBSCRIBER_SUCCESS,
  ADD_SUBSCRIBER_FAIL,
  REMOVE_SUBSCRIBER_STARTED,
  REMOVE_SUBSCRIBER_SUCCESS,
  REMOVE_SUBSCRIBER_FAIL,
  SUBSCRIBER_DATA_UPDATE_STARTED,
  SUBSCRIBER_DATA_UPDATE_SUCCESS,
  SUBSCRIBER_DATA_UPDATE_FAIL,
} from '../actions/actionTypes';

const initialState = {
  subscribers: [], // Subscriber details
  subscriberLoading: false, // Showing that the API is in progress
  subscriberError: '', // Manage Error in camera API
  removeSubscriberLoading: false,
  removeSubscriberError: '',
};

const subscriber = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_SUBSCRIBER_STARTED:
    case SUBSCRIBER_DATA_STARTED:
    case SUBSCRIBER_DATA_UPDATE_STARTED: {
      return {
        ...state,
        subscriberLoading: true,
        subscriberError: '',
        removeSubscriberLoading: false,
        removeSubscriberError: '',
      };
    }

    case ADD_SUBSCRIBER_SUCCESS: {
      return {
        ...state,
        subscriberLoading: false,
        subscriberError: '',
        removeSubscriberLoading: false,
        removeSubscriberError: '',
      };
    }

    case SUBSCRIBER_DATA_SUCCESS: {
      const { subscribers } = action.payload;
      return {
        subscribers,
        subscriberLoading: false,
        subscriberError: '',
        removeSubscriberLoading: false,
        removeSubscriberError: '',
      };
    }

    case SUBSCRIBER_DATA_UPDATE_SUCCESS: {
      return {
        ...state,
        subscriberLoading: false,
        subscriberError: '',
        removeSubscriberLoading: false,
        removeSubscriberError: '',
      };
    }

    case ADD_SUBSCRIBER_FAIL:
    case SUBSCRIBER_DATA_FAIL:
    case SUBSCRIBER_DATA_UPDATE_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        subscriberLoading: false,
        subscriberError: error,
        removeSubscriberLoading: false,
        removeSubscriberError: '',
      };
    }

    case REMOVE_SUBSCRIBER_STARTED: {
      return {
        ...state,
        subscriberLoading: false,
        subscriberError: '',
        removeSubscriberLoading: true,
        removeSubscriberError: '',
      };
    }

    case REMOVE_SUBSCRIBER_SUCCESS: {
      return {
        ...state,
        subscriberLoading: false,
        subscriberError: '',
        removeSubscriberLoading: false,
        removeSubscriberError: '',
      };
    }

    case REMOVE_SUBSCRIBER_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        subscriberLoading: false,
        subscriberError: '',
        removeSubscriberLoading: false,
        removeSubscriberError: error,
      };
    }

    default:
      return state;
  }
};

export default subscriber;
