import {
  VERIFY_TOKEN_STARTED,
  VERIFY_TOKEN_END,
  USER_LOGIN_STARTED,
  USER_LOGIN_FAILURE,
  VERIFY_USER_SUCCESS,
  USER_LOGOUT,
  UPDATE_USER,
  UPDATE_LAST_VISITED_ROUTE,
} from '../actions/actionTypes';

// define initial state of auth reducer
const initialState = {
  token: null, // manage the access token
  expiredAt: null, // manage expiry time of the access token
  user: {}, // manage the user details
  authLoading: false, // to indicate that the auth API is in progress
  isAuthenticated: false, // consider as a authentication flag
  userLoginLoading: false, // to indicate that the user signin API is in progress
  loginError: '', // manage the error of the user signin API,
  lastVisitedRoute: '',
  role: null,
};

// update store based on type and payload and return the state
const auth = (state = initialState, action: any) => {
  switch (action.type) {
    // verify token - started
    case VERIFY_TOKEN_STARTED: {
      const { silentAuth } = action.payload;
      return silentAuth ? { ...state } : { ...initialState, authLoading: true };
    }
    // verify token - ended/failed
    case VERIFY_TOKEN_END: {
      return {
        ...state,
        authLoading: false,
      };
    }
    // user login - started
    case USER_LOGIN_STARTED: {
      return {
        ...state,
        userLoginLoading: true,
        loginError: '',
      };
    }
    // user login - ended/failed
    case USER_LOGIN_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        loginError: error,
        userLoginLoading: false,
      };
    }
    // verify token - success
    case VERIFY_USER_SUCCESS: {
      const { token, expiredAt, user } = action.payload;
      return {
        ...state,
        token,
        expiredAt,
        user,
        loginError: '',
        isAuthenticated: true,
        authLoading: false,
        userLoginLoading: false,
        role: user.role.toLowerCase(),
      };
    }
    // handle user logout
    case USER_LOGOUT: {
      return {
        ...initialState,
        authLoading: false,
      };
    }
    // update user info
    case UPDATE_USER: {
      const { user } = action.payload;
      const { name, email, phone, avatar_url } = user;
      return {
        ...state,
        user: {
          ...state.user,
          name,
          email,
          phone,
          avatar_url,
        },
      };
    }
    //update last visited route
    case UPDATE_LAST_VISITED_ROUTE: {
      const { url } = action.payload;
      return {
        ...state,
        lastVisitedRoute: url,
      };
    }
    default:
      return state;
  }
};

export default auth;
