import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import User from 'assets/images/user.webp';

const AuthHeader = (): JSX.Element => {
  const authObj = useSelector((state: any) => state.auth);
  const { user: stateUser = {} } = authObj;
  const [user, setUser] = useState({ name: '', avatar_url: '', org_name: '' });

  useEffect(() => {
    setUser(stateUser);
  }, [user, stateUser]);

  return (
    <div className='hidden sm:flex justify-between items-center p-4 md:py-3 md:px-6 lg:px-8 sm:sticky sm:top-0 z-100 bg-white border-b border-gray-200'>
      <span className='flex flex-row'>
        <span className='text-lg md:text-sm text-gray-600 font-medium'>
          Welcome{' '}
          <span className='font-semibold text-brand-600 capitalize'>
            {user.name}!
          </span>{' '}
        </span>
      </span>
      <div className='flex items-center space-x-3'>
        <div className='flex text-sm focus:outline-none items-center space-x-2'>
          <NavLink
            to={`/eai/organization`}
            className='flex text-sm focus:outline-none items-center space-x-2'
          >
            <img
              className='h-8 w-8 rounded-full p-1 ring-2 ring-gray-300'
              src={user.avatar_url || User}
              alt={user && user.name}
              loading='lazy'
            />
            <span className='text-xl md:text-sm text-black font-medium capitalize'>
              {user && user.name}
            </span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
export default AuthHeader;
