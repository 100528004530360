import JUNOLogo from "assets/images/logo.png";

const Logo = () => {
  return (
    <div>
      <img src={JUNOLogo} loading="lazy" className="w-full h-24" alt="JunoAI" />
    </div>
  );
};
export default Logo;
