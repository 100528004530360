import { Navigate, useLocation } from 'react-router';

type PublicProps = {
  isAuthenticated: boolean;
  children: JSX.Element;
};

const PublicRoute = ({ isAuthenticated, children }: PublicProps) => {
  const location = useLocation();

  if (isAuthenticated) {
    return <Navigate to={`/eai`} state={{ from: location }} />;
  }

  return children;
};
export default PublicRoute;
