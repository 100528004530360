import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Logo from "assets/images/logo.png";
import { Navigation } from "constants/Navigation";
import { userLogoutAsync } from "redux/asyncActions/authAsyncActions";
import Spinner from "./Spinner";

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);

  const logout = async () => {
    try {
      setLoader(true);
      setTimeout(() => {
        dispatch(userLogoutAsync());
        setLoader(false);
        localStorage.removeItem("location");
      }, 1000);
    } catch (err) {
      setLoader(false);
    }
  };

  return (
    <div className="h-screen hidden md:flex md:flex-shrink-0 bg-white print:hidden border-r border-gray">
      <div className="flex flex-col w-64">
        <div className="flex flex-col flex-grow h-full">
          <div className="flex items-center justify-between flex-shrink-0 pl-1 py-2 pr-3">
            <NavLink
              className="flex items-center no-underline-all space-x-2 px-2"
              aria-label="Dashboard"
              to="/eai"
            >
              <img
                className="h-16 w-auto"
                src={Logo}
                alt="JunoAI"
                loading="lazy"
              />
            </NavLink>
          </div>
          <div className="mt-3 flex-1 flex flex-col overflow-y-auto">
            <nav id="sidebar" className="flex-1">
              <div className="pb-6 space-y-1 text-gray-400">
                {Navigation &&
                  Navigation.map((item: any) => (
                    <NavLink
                      key={item.href}
                      to={`${item.href}`}
                      className={`${
                        location.pathname === `${item.href}`
                          ? "text-brand bg-brand-100 border-l-4 border-brand px-3 font-semibold"
                          : "text-black px-4"
                      }  group flex items-center py-2 text-sm no-underline-all hover:text-brand hover:bg-brand-100`}
                      aria-current="page"
                    >
                      <span>{item.icon}</span>
                      <span className="ml-2">{item.name}</span>
                    </NavLink>
                  ))}
              </div>
            </nav>
          </div>
          <div className="border-t border-gray">
            <div className="flex-shrink-0 w-full group block no-underline-all px-4 py-2">
              <button
                className="group relative flex items-center justify-center w-full p-3 bg-brand text-white font-medium rounded-full shadow-sm hover:bg-brand-700"
                onClick={logout}
              >
                {loader ? (
                  <div>
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3 text-white">
                      <Spinner />
                    </span>
                    <span>Logging out...</span>
                  </div>
                ) : (
                  <span>Logout</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Sidebar);
